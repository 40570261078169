<template>
  <a-modal v-model="visible" :title="form.ru ? `${form.ru.name}` : ''" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" :disabled="isDisabled" type="primary" @click.prevent.stop="submit">
        {{ action === 'create' ? 'Создать' : 'Сохранить изменения'}}
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item label="Название(ru)" required>
        <a-input  v-model="form.ru.name" />
      </a-form-model-item>

      <a-form-model-item label="Название(ua)" required>
        <a-input  v-model="form.ua.name" />
      </a-form-model-item>

      <a-form-model-item label="Позиция" required>
        <a-input  v-model.number="form.position" />
      </a-form-model-item>

      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active" placeholder="выберите статус">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <p>Список блока</p>
      <div v-for="(value, index) in form.footer_values" class="value_list" :key="value.id">
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="form.footer_values.splice(index, 1)"
        >
          <a-icon class="remove_icon" type="close-circle" :style="{ fontSize: '16px', color: '#ef0c27' }" />
        </a-popconfirm>

        <a-form-model-item label="Название(ru)" required>
          <a-input v-model="value.ru.name" />
        </a-form-model-item>
        <a-form-model-item label="Название(ua)" required>
          <a-input v-model="value.ua.name" />
        </a-form-model-item>
        <a-form-model-item label="Ссылка" required>
          <a-input v-model="value.alias" />
        </a-form-model-item>
      </div>
      <a-button type="primary" @click.prevent.stop="form.footer_values.push({ ru: {name: ''}, ua: {name: ''}, alias: '' })">
        Добавить елемент
      </a-button>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  name: 'EditFooterBlockModal',

  data() {
    return {
      visible: false,
      statuses,
      action: '',
      form: {
        footer_values: [],
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
    }
  },

  computed: {
    isDisabled() {
      let isDisabled = false
      const form = this.form

      if (!form.ru?.name || !form.ua?.name) {
        isDisabled = true
      }

      // eslint-disable-next-line camelcase
      for (const item of form.footer_values) {
        if (!item?.ru?.name || !item.ua?.name || !item.alias) {
          isDisabled = true
        }
      }

      return isDisabled
    },
  },

  methods: {
    async open(id, action) {
      this.visible = true
      this.action = action
      if (action === 'edit') {
        try {
          const form = (await this.$services.get(`admin/footers/${id}`)).data.data.footer
          this.form = {
            ...this.form,
            ...form,
          }
        } catch (e) {
          console.error(e)
        }
      }
    },

    async submit() {
      if (this.action === 'create') {
        try {
          await this.$services.post('admin/footers', this.form)
          this.close()
          this.$emit('getList')
        } catch (e) {
          console.error(e)
        }
      } else {
        try {
          await this.$services.put(`admin/footers/${this.form.id}`, this.form)
          this.close()
          this.$emit('getList')
        } catch (e) {
          console.error(e)
        }
      }
    },

    close() {
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
        footer_values: [],
      }
      this.visible = false
      this.action = ''
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  .value_list {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
    position: relative;
  }
  .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
      width: auto;
      height: 100%;
      max-width: 30px;
      margin: 0 auto;
      display: block;
    }
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  .remove_icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
</style>
