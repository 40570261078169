<template>
  <div>
    <a-table :columns="columns" :dataSource="list" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index + record.name">
      <span
        slot="id"
        slot-scope="index"
      >{{index}}</span>
      <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
      <span slot="position" slot-scope="record"> {{ record.position }}</span>
      <span v-if="record.id !== 0" slot="active" slot-scope="record">{{ record.active ? 'Активный' : 'Не активный' }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="record.id !== 0 ? $refs.editModal.open(record.id, 'edit') : $refs.editSocialModal.open()">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
          <a-popconfirm
            v-if="record.id !== 0"
            title="Вы уверенны что хотите удалить?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="$emit('removeItem', record.id)"
          >
          <a href="javascript: void(0);" class="btn btn-sm btn-light">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-footer-block-modal ref="editModal" @getList="$emit('getList')" />
    <edit-footer-social-block ref="editSocialModal" @getList="$emit('getList')" />
  </div>
</template>

<script>

import EditFooterBlockModal from '@/components/footer/EditFooterBlockModal'
import EditFooterSocialBlock from '@/components/footer/EditFooterSocialBlock'

export default {
  name: 'FooterTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  components: { EditFooterBlockModal, EditFooterSocialBlock },
}
</script>

<style scoped lang="scss">
</style>
