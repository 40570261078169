export default {
  region: 'Регионы',
  city: 'Города',
  district: 'Районы',
  category: 'Категории',
  station: 'Станции',
  realty_type: 'Тип недвижимости',
  operation_type: 'Тип операций',
  life_type: 'Тип жилья',
  heating: 'Тип отопления',
  layout: 'Планировка',
  currency: 'Валюта',
  floor: 'Этаж',
  apartment: 'Количесво комнат',
}
